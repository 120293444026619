<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${product.name}` }} - {{ $t("COMMON.SUPPLIERS") }}
      </span>
    </div>

    <supplier-product-list
      :filterProduct="product.id"
      :filterOrganization="product.organization.id"
      :product="product"
      :addLineText="$t('PRODUCTS.ADD_SUPPLIER')"
    />
  </div>
</template>
<script>
import SupplierProductList from "@/components/SupplierProduct/SupplierProductList.vue";

export default {
  name: "product-view-suppliers",

  components: {
    SupplierProductList,
  },

  props: ["product"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
