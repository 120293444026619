<template>
  <span
    v-if="product.status === STATUS_ACTIVE && isNew"
    style="display: inline; margin-left: 8px"
  >
    <badge type="info">
      {{ $t("COMMON.NEW") }}
    </badge>
  </span>
</template>

<script>
import { STATUS_ACTIVE } from "@/constants/products";
import moment from "moment";

export default {
  name: "product-novelty-badge",

  components: {},

  mixins: [],

  props: ["product"],

  data() {
    return {
      isNew: false,
      STATUS_ACTIVE,
    };
  },

  computed: {},

  methods: {
    checkProductOldest() {
      const createdDate = moment(this.product.created_at);
      const currentDate = moment();
      const daysDifference = currentDate.diff(createdDate, "days");
      this.isNew = daysDifference < 30;
    },
  },

  mounted() {
    this.checkProductOldest();
  },

  watch: {},
};
</script>
