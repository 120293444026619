<template>
  <div>
    <div style="display: inline">
      <badge :type="getDeliveryBadgeType()">
        {{ $t(`PRODUCTS.STATUS_${product.status}`) }}
      </badge>
    </div>
  </div>
</template>

<script>
import {
  STATUS_ACTIVE,
  STATUS_DELETED,
  STATUS_INACTIVE,
} from "@/constants/products";

export default {
  name: "product-status-badge",

  components: {},

  mixins: [],

  props: ["product"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    getDeliveryBadgeType() {
      switch (this.product.status) {
        case STATUS_ACTIVE:
          return "success";
        case STATUS_INACTIVE:
          return "primary";
        case STATUS_DELETED:
          return "danger";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
