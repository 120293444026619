<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${product.name}` }} - {{ $t("COMMON.PURCHASES_ORDERS") }}
      </span>
    </div>

    <purchases-order-list-table
      :filterProduct="product.id"
      :filterOrganization="product.organization.id"
      :product="product"
    />
  </div>
</template>
<script>
import PurchasesOrderListTable from "../../../PurchasesModule/PurchasesOrderManagement/partials/PurchasesOrderListTable.vue";

export default {
  name: "product-view-purchases-orders",

  components: {
    PurchasesOrderListTable,
  },

  props: ["product"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
