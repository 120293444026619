<template>
  <div class="">
    <base-input>
      <el-select
        style="width: calc(100% - 50px)"
        v-model="productModel"
        @change="productChanged"
        :placeholder="$t('COMMON.PRODUCT')"
        :filterable="filterable"
        :multiple="true"
        :disabled="disabled"
        remote
        :remote-method="getProducts"
        collapse-tags
        :loading="loading"
        aria-describedby="button-addon"
      >
        <el-option v-if="showAll" :value="null" :label="$t('COMMON.PRODUCT')">
        </el-option>
        <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
        </el-option>
        <el-option
          v-for="(product, key) in productList"
          :key="key"
          :value="product.id"
          :label="`${product.code} - ${product.name}`"
        >
        </el-option>
      </el-select>
      <i
        style="cursor: pointer"
        slot="append"
        class="fas fa-search px-1"
        @click="toggleModal"
      ></i>
    </base-input>

    <modal
      :show="openModal && !disabled"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div class="">
        <div class="row">
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <base-input>
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  :placeholder="$t('COMMON.PER_PAGE')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div
              class="col-sm-6 col-md-4"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
            >
              <base-input>
                <organization-selector
                  @organizationChanged="
                    (organizationId) => (selectedOrganization = organizationId)
                  "
                />
              </base-input>
            </div>

            <div
              class="col-sm-6 col-md-4"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            >
              <base-input :placeholder="`${$t('COMMON.LOCATIONS')}`">
                <locations-selector
                  @locationsChanged="
                    (locations) => (selectedLocations = locations)
                  "
                />
              </base-input>
            </div>
            <div class="col-6 col-md-4">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                :placeholder="$t('COMMON.SEARCH')"
                clearable
              />
            </div>

            <div
              class="col-6 col-md-4"
              v-if="
                !filterSupplier &&
                $currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)
              "
            >
              <base-input :placeholder="`${$t('COMMON.LOCATIONS')}`">
                <supplier-selector
                  :multiple="false"
                  :allowNone="true"
                  :showAll="false"
                  @supplierChanged="(supplier) => (selectedSupplier = supplier)"
                />
              </base-input>
            </div>
            <div class="col-6 col-md-2">
              <base-input>
                <el-select
                  :clearable="true"
                  class="select-primary pagination-select"
                  v-model="product_status"
                  :placeholder="$t('COMMON.STATUS')"
                >
                  <el-option
                    class="select-primary"
                    v-for="(item, key) in PRODUCT_STATUS"
                    :key="key"
                    :label="$t(`PRODUCTS.STATUS_${item}`)"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-6 col-md-2">
              <base-checkbox v-model="latestProduct" class="mb-3">
                <span class="form-control-label">
                  {{ $t("COMMON.NEW") }}
                </span>
              </base-checkbox>
            </div>
          </div>
        </div>
        <div class="bg-white" style="max-height: 300px; overflow-y: scroll">
          <el-table
            ref="multipleTableRef"
            :data="productList"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />

            <el-table-column :label="$t('COMMON.CODE')" prop="code" />
            <el-table-column :label="$t('PRODUCTS.SKU')" prop="sku" />
            <el-table-column :label="$t('COMMON.NAME')" prop="name" />
            <el-table-column
              :label="$t('COMMON.SELLING_PRICE')"
              prop="selling_price"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $formatCurrency(row.selling_price) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.BUYING_PRICE')"
              prop="selling_price"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $formatCurrency(row.buying_price) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.STATUS')">
              <template v-slot="{ row }">
                <div class="d-flex">
                  <product-status-badge :product="row" />
                  <product-novelty-badge :product="row" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          slot="footer"
          class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
        >
          <div class="">
            <p class="card-category">
              {{
                $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
                  from: total ? from + 1 : 0,
                  to: to,
                  of: total,
                })
              }}
              <span v-if="selectedRows.length">
                &nbsp; &nbsp;
                {{
                  $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length })
                }}
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>

        <div class="d-flex justify-content-between" style="margin-top: 20px">
          <div class="">
            <el-button @click="selectAll">
              {{ $t("COMMON.SELECT_ALL") }}
            </el-button>
            <el-button @click="unSelectAll">
              {{ $t("COMMON.UN_SELECT_ALL") }}
            </el-button>
          </div>
          <div class="">
            <el-button type="danger" @click="close">
              {{ $t("COMMON.CLOSE") }}
            </el-button>
            <el-button type="success" @click="toggleModal">
              {{ $t("COMMON.VALIDATE") }}
            </el-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Button, Option, Select, Table, TableColumn } from "element-ui";
import OrganizationSelector from "./OrganizationSelector";
import LocationsSelector from "./LocationsSelector";
import _, { difference } from "lodash";
import ProductStatusBadge from "./ProductStatusBadge";
import ProductNoveltyBadge from "./ProductNoveltyBadge";
import { PRODUCT_STATUS, STATUS_ACTIVE } from "@/constants/products";
import SupplierSelector from "./SupplierSelector";
import BasePagination from "./BasePagination";

export default {
  name: "products-selector",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    OrganizationSelector,
    LocationsSelector,
    ProductStatusBadge,
    ProductNoveltyBadge,
    BasePagination,
    SupplierSelector,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    product: {
      type: String,
      default: null,
      description: "Product id",
    },
    includes: {
      type: String,
      default: null,
      description: "Includes",
    },
    products: {
      type: Array,
      default: () => [],
      description: "Default Products",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterWarehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterIds: {
      type: Array,
      default: null,
      description: "products ids",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Id not in",
    },
    filterCanBeSold: {
      type: [Boolean, null],
      default: null,
      description: "Can the product be sold",
    },
    filterCanBePurchased: {
      type: [Boolean, null],
      default: null,
      description: "Can the product be purchased",
    },
  },

  data() {
    return {
      productModel: this.products.length
        ? [...this.products].map((item) => item.id)
        : [],
      productList: [],
      loading: false,
      multipleTableRef: null,
      multipleSelection: null,
      openModal: false,
      selectedLocations: [],
      selectedProductData: [],
      selectedOrganization: null,
      query: null,
      latestProduct: false,
      product_status: STATUS_ACTIVE,
      selectedRows: [],
      PRODUCT_STATUS,
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      selectedSupplier: null,
    };
  },

  setup() {},

  created() {
    this.getProducts();
  },

  mounted() {},

  methods: {
    getListDebounced: _.debounce(function () {
      this.getProducts();
    }, 300),

    handleSelectionChange(products) {
      const productsNotSelectedIds = [];
      this.productList.forEach((selectedProduct) => {
        const foundProduct = products.find(
          (product) => product.id === selectedProduct.id
        );
        if (!foundProduct) {
          productsNotSelectedIds.push(selectedProduct.id);
        }
      });
      this.productModel = [...products].map((item) => item.id);
      const newProducts = products.filter(
        (product) =>
          !this.selectedProductData.some(
            (selected) => selected.id === product.id
          )
      );
      const nonSelectedIdsSet = new Set(productsNotSelectedIds);
      const selectedProductData = [
        ...newProducts,
        ...this.selectedProductData,
      ].filter((item) => !nonSelectedIdsSet.has(item.id));

      this.$emit(
        "productChanged",
        [...selectedProductData].map((item) => ({
          type: "products",
          id: item.id,
        })),
        [...selectedProductData]
      );

      this.selectedProductData = [...selectedProductData];
    },

    selectAll() {
      this.$refs.multipleTableRef.toggleAllSelection();
    },

    unSelectAll() {
      this.$refs.multipleTableRef.clearSelection();
    },

    async toggleModal() {
      this.openModal = !this.openModal;
      const products = this.productModel?.length ? this.productModel : [];
      if (this.openModal) {
        const selectedProducts = [...this.productList].filter(function (
          product
        ) {
          return products.includes(product.id);
        });
        if (this.$refs.multipleTableRef) {
          await this.unSelectAll();
          selectedProducts.forEach((item) => {
            this.$refs.multipleTableRef.toggleRowSelection(item);
          });
        }
      }
    },

    close() {
      this.openModal = false;
    },

    async defaultDataSelection() {
      const selectedProductData = [...this.selectedProductData];
      const commonElements = [];
      await this.selectedProductData.forEach((selectedProduct) => {
        const foundProduct = this.productList.find(
          (product) => product.id === selectedProduct.id
        );
        if (foundProduct) {
          commonElements.push(foundProduct);
        }
      });
      if (this.$refs.multipleTableRef) {
        commonElements.forEach((item) => {
          this.$refs.multipleTableRef.toggleRowSelection(item);
        });
      }
      this.$emit(
        "productChanged",
        [...selectedProductData].map((item) => ({
          type: "products",
          id: item,
        })),
        selectedProductData
      );
    },

    async getProducts(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(this.query ? { search: this.query } : {}),
            ...(id ? { id: id } : {}),
            ...(this.product_status ? { status: this.product_status } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: this.includes ? this.includes : undefined,
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.filterSupplier },
          };
        }
        if (this.latestProduct) {
          params = {
            ...params,
            filter: { ...params.filter, latestProduct: this.latestProduct },
          };
        }
        if (this.filterIds) {
          params = {
            ...params,
            filter: { ...params.filter, ids: this.filterIds },
          };
        }
        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        if (this.selectedSupplier) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              supplierProduct: this.selectedSupplier,
            },
          };
        }
        if (this.filterSupplier) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              supplierProduct: this.filterSupplier,
            },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        if (this.filterCanBeSold != null) {
          params = {
            ...params,
            filter: { ...params.filter, can_be_sold: this.filterCanBeSold },
          };
        }
        if (this.filterCanBePurchased != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              can_be_purchased: this.filterCanBePurchased,
            },
          };
        }

        await this.$store.dispatch("products/list", params);
        this.productList = await this.$store.getters["products/list"];
        this.total = this.$store.getters["products/listTotal"];
        this.loading = false;
        await this.defaultDataSelection();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    productChanged(products) {
      const selectedProducts = [...this.productList].filter(function (product) {
        return [...products].includes(product.id);
      });
      if (this.openModal) {
        if (this.$refs.multipleTableRef) {
          selectedProducts.forEach((item) => {
            this.$refs.multipleTableRef.toggleRowSelection(item);
          });
        }
      }
      this.productModel = products;
      this.$emit(
        "productChanged",
        [...products].map((item) => ({ type: "products", id: item })),
        selectedProducts
      );
    },
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    products(products) {
      if (products?.length) {
        const productsData = products.map((item) => item.id);
        if (difference(productsData, this.productModel).length !== 0) {
          this.productModel = productsData;
        }
      } else {
        this.productModel = [];
      }
    },
    filterOrganization(filterOrganization) {
      this.getProducts();
    },
    filterReseller(filterReseller) {
      this.getProducts();
    },
    filterSupplier: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouse(filterWarehouse) {
      this.getProducts();
    },
    filterIds(filterIds) {
      this.getProducts();
    },
    filterCanBeSold(filterCanBeSold) {
      this.getProducts();
    },
    filterCanBePurchased(filterCanBePurchased) {
      this.getProducts();
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: false,
    },
    pagination: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: false,
    },
    query: {
      handler: "getListDebounced",
      immediate: false,
    },
    product_status: {
      handler: "getListDebounced",
      immediate: true,
    },
    latestProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSupplier: {
      handler: "getListDebounced",
      immediate: false,
    },
    filterProductsNotInWarehouseId: {
      handler: "getListDebounced",
      immediate: false,
    },
  },
};
</script>
