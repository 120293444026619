<template>
  <span
    class="mx-2"
    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CATEGORIES) && category"
  >
    <router-link
      :style="`background-color:${category.color + '7F'};color:${
        category.color
      };`"
      class="badge"
      :to="{ name: 'View Category', params: { id: category.id } }"
    >
      <img
        v-if="category.icon"
        :src="`${category.icon}`"
        class="mb2"
        style="width: 20px; height: 20px; border-radius: 4px"
        alt=""
      />
      {{ `${category.name}` }}
    </router-link>
  </span>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "category",

  components: {},

  mixins: [formMixin],

  props: {
    category: {
      type: Object,
      default: () => ({}),
      description: "Organization",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    category(category) {},
  },
};
</script>
