<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${product.name}` }} - {{ $t("COMMON.WAREHOUSES") }}
      </span>
    </div>

    <warehouse-product-list
      :filterProduct="product.id"
      :filterOrganization="product.organization.id"
    />
  </div>
</template>
<script>
import WarehouseProductList from "@/components/WarehouseProduct/WarehouseProductList.vue";

export default {
  name: "product-view-warehouses-products",

  components: {
    WarehouseProductList,
  },

  props: ["product"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
